import ordinal from 'ordinal';
import React from 'react';
import { Link } from 'react-router-dom';
import { Spinner, Table } from 'reactstrap';
// import Button from '../../components/Button';
import { useParams } from 'react-router-dom';
import InviteTeamForm from '../../components/Tournaments/InviteTeamForm';
import useTournament from '../../hooks/useTournament';
import Header from '../Header';
import './styles.css';
import useTournamentInvites from '../../hooks/useTournamentInvites';
import { CheckCircle, Circle, Mail } from 'react-feather';
import useTournamentInvite from '../../hooks/useTournamentInvite';

const TournamentsDetailPage = () => {
  const { id: tournamentKey } = useParams();
  const { tournament } = useTournament(tournamentKey);
  const { updateInvite, sendInvite, upsertLoading: loadingInvite } = useTournamentInvite();
  const { invites, mutate: mutateInvites } = useTournamentInvites();

  const toggleIsActive = async (team) => {
    console.log('team', team);
    team.isActive = !team.isActive;
    await updateInvite(team);
  };

  const sendTournamentInvite = async (id) => {
    await sendInvite(id);
    await mutateInvites();
  };

  return (
    <div className="page-container">
      <Header currentPage="/tournaments" />
      <div className="container-fluid">
        <div className="content-box">
          <div className="row">
            <div className="col-6">
              <h3>Tournament Details</h3>
              {/* <pre>{JSON.stringify(inviteError)}</pre> */}

              <div>{`Name: ${tournament.name}`}</div>
              <div>{`Key: ${tournament.key}`}</div>
              <div>{`Open Date: ${tournament.openDate}`}</div>
              <div>
                <Link to={`/tournaments/${tournament.key}/edit`} className="btn btn-success">
                  Edit
                </Link>
              </div>
            </div>
            <div className="col-6">
              <h3>Events:</h3>
              {/* <pre>{JSON.stringify(tournament, null, 2)}</pre> */}
              <Table striped hover responsive size="sm" className="element-list">
                <thead>
                  <tr>
                    <th>Event</th>
                    <th>Spots</th>
                    <th>Registered Teams</th>
                  </tr>
                </thead>
                <tbody>
                  {tournament &&
                    tournament.events &&
                    tournament.events.map((e) => (
                      <tr key={e.id}>
                        <td>
                          <Link to={`/events/${e.id}`}>{e.name}</Link>
                        </td>
                        <td>{e.tournamentMaxTeams}</td>
                        <td>{e.registeredTeamCount}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
            <div className="col-12">
              <h3>Teams Registered for the Tournament</h3>
              <Table striped hover responsive size="sm" className="element-list">
                <thead>
                  <tr>
                    <th></th>
                    <th>Team Name</th>
                    <th>Username</th>
                    <th>Captain Email</th>
                    <th>Event</th>
                    <th>Paid</th>
                    <th>Players</th>
                    <th>In Tournament</th>
                    <th>Place in Finals</th>
                  </tr>
                </thead>
                <tbody>
                  {tournament &&
                    tournament.tournamentTeams &&
                    tournament.tournamentTeams.map((t) => (
                      <tr key={t.id}>
                        <td>
                          {/* <div>{JSON.stringify(t)}</div> */}
                          {t.registeredTeamId}
                        </td>

                        <td>
                          {/* <div>{JSON.stringify(t)}</div> */}
                          {t.registeredTeam.name}
                        </td>
                        <td>{t.registeredTeam.teamUsername}</td>
                        <td>
                          {/* <div>{JSON.stringify(t)}</div> */}
                          {t.email}
                        </td>
                        <td>{t.event ? <>{t.event.name}</> : ''}</td>
                        <td>{t.amountPaid.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) || 0}</td>

                        <td>{t.players}</td>
                        <td>
                          {t.inTournament === true && <div> Yes</div>}
                          {t.inTournament === false && <div> No</div>}
                        </td>
                        <td>{t.placeInFinals ? ordinal(t.placeInFinals * 1) : ''}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>

              <h3>Send Invites</h3>
              <InviteTeamForm
                tournamentKey={tournamentKey}
                onSubmit={() => {
                  mutateInvites();
                }}
              />

              <h3>Team Invites</h3>
              <Table striped hover responsive size="sm" className="element-list">
                <thead>
                  <tr>
                    <th>Team Name</th>
                    <th>Event</th>
                    <th>Invite Active</th>
                    <th>Send Email</th>
                    <th>Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {invites &&
                    invites.map((invite) => (
                      <tr key={invite.id}>
                        <td>
                          {/* <div>{JSON.stringify(t)}</div> */}
                          {invite.registeredTeam.name} [{invite.registeredTeam.teamUsername}]
                        </td>
                        <td>
                          {/* <pre>{JSON.stringify(invite)}</pre> */}
                          {invite.event ? (
                            <span>
                              {invite.event.name}
                              <br /> {invite.event.location.name}
                            </span>
                          ) : (
                            ''
                          )}
                        </td>
                        <td>
                          <div className="clickable" onClick={() => toggleIsActive(invite)}>
                            {invite.isActive ? <CheckCircle color="green" /> : <Circle color="red" />}
                          </div>
                        </td>
                        <td>
                          {loadingInvite && <Spinner />}
                          {!loadingInvite && (
                            <div className="clickable" onClick={() => sendTournamentInvite(invite.id)}>
                              {invite.notificationSentCount > 0 ? <Mail color="green" /> : <Mail color="gray" />} ({invite.notificationSentCount || 0}
                              )
                            </div>
                          )}
                        </td>
                        <td>${invite.cost || 0}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TournamentsDetailPage.propTypes = {};
TournamentsDetailPage.defaultProps = {};

export default TournamentsDetailPage;
// const mapStateToProps = (state) => extend({}, state.tournaments, state.global);
// const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
// export default connect(mapStateToProps, matchDispatchToProps)(TournamentsDetailPage);
